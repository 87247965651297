<template>
  <b-navbar type="dark" toggleable="lg">
    <b-container>
      <jb-burger />
      <jb-nav-collapse ref="navCollapse">
        <jb-nav-item v-for="item in navItems" :key="item.to" :nav-item="item" />
        <jb-nav-item
          :nav-item="{
            href: 'https://www.instagram.com/4in1cleancz/',
            target: '_blank',
            class: 'ml-auto'
          }"
        >
          <template #icon>
            <fa :icon="['fab', 'instagram']" fixed-width />
          </template>
        </jb-nav-item>
        <!--        <jb-nav-item-->
        <!--          :nav-item="{-->
        <!--            href: 'https://4in1clean.de',-->
        <!--            content: 'DE',-->
        <!--            target: '_blank'-->
        <!--          }"-->
        <!--        />-->
        <client-only>
          <template v-if="$auth.loggedIn">
            <jb-nav-item
              :nav-item="{
                to: '/tajne/objednavky',
                content: 'Objednávky'
              }"
              @click="hide"
            />
            <jb-nav-item
              :nav-item="{
                content: 'Odhlásit se'
              }"
              @click="logout"
            >
              <template #icon>
                <fa icon="sign-out-alt" fixed-width />
              </template>
            </jb-nav-item>
          </template>
        </client-only>
      </jb-nav-collapse>
    </b-container>
  </b-navbar>
</template>
<script>
import JbNavCollapse from '~/components/Core/JbNav/JbNavCollapse'
import JbNavItem from '~/components/Core/JbNav/JbNavItem'
import JbBurger from '~/components/Core/JbNav/JbBurger'

require('es6-promise').polyfill()
export default {
  name: 'JbNav',
  components: {
    JbNavCollapse,
    JbNavItem,
    JbBurger
  },
  data: () => ({
    seznamCeniku: new Array(6),
    navItems: [
      {
        to: '/',
        content: 'Úvod'
      },
      {
        to: '/objednat',
        content: 'Objednat'
      },
      {
        to: '/pred-a-po-pouziti',
        content: 'Před a po použití'
      },
      {
        to: '/aplikace',
        content: 'Aplikace'
      },
      {
        to: '/vseobecne-obchodni-podminky',
        content: 'Obchodní podmínky'
      },
      {
        to: '/obchodni-zastoupeni',
        content: 'Prodejny'
      }
    ]
  }),
  methods: {
    logout () {
      this.hide()
      this.$auth.logout()
    },
    hide () {
      if (this.$refs.navCollapse?.$refs?.menuSidebar?.hide) {
        this.$refs.navCollapse.$refs.menuSidebar.hide()
      }
    }
  }
}
</script>

<style lang="scss">
@import "~assets/scss/vars/initial";
@import "~assets/scss/partials/main-menu";
</style>
