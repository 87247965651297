<template>
  <component
    :is="navItem.navItems ? 'b-nav-item-dropdown' : 'b-nav-item'"
    ref="menuLink"
    v-b-hover="($event) => hoverHandler($event)"
    :to="navItem.to"
    :href="navItem.href"
    :active="navItem.active"
    :no-caret="!!navItem.navItems"
    :dropdown-link-classes="isActiveDropdown(navItem) ? 'active' : ''"
    :target="navItem.target"
    :class="navItem.class"
    v-on="$listeners"
  >
    <template v-if="navItem.navItems" #button-content>
      <span style="pointer-events: none;">
        {{ navItem.content }}<fa
          icon="chevron-up"
          fixed-width
          class="ml-2"
        /></span>
    </template>
    <slot name="icon" />
    <span v-if="!navItem.navItems" style="pointer-events: none;">{{
      navItem.content
    }}</span>

    <template v-if="navItem.navItems">
      <b-dropdown-item
        v-for="(navItemChild, index) in navItem.navItems"
        :key="index"
        :to="navItemChild.to"
        :active="navItemChild.active"
      >
        <fa icon="angle-right" fixed-width class="mr-2" />
        {{ navItemChild.content }}
      </b-dropdown-item>
    </template>
  </component>
</template>
<script>
import { BNavItemDropdown } from '~/components/BootstrapVue/NavItemDropdown/nav-item-dropdown'
import { BDropdownItem } from '~/components/BootstrapVue/DropdownItem/dropdown-item'

export default {
  name: 'JbNavItem',
  components: {
    BNavItemDropdown,
    BDropdownItem
  },
  props: {
    navItem: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dropdownTimeout: null
  }),
  methods: {
    hoverHandler (isHovered, child = false) {
      if (this.navItem.navItems) {
        const vm = this
        if (isHovered) {
          clearTimeout(this.dropdownTimeout)
          this.$refs.menuLink.visible = true
        } else if (!child) {
          this.dropdownTimeout = setTimeout(function () {
            vm.$refs.menuLink.visible = false
          }, 250)
        }
      }
    },
    isActiveDropdown (navItem) {
      const vm = this
      return navItem.navItems
        ? (navItem.navItems.some(item => item.to === vm.$route.path)) ||
        navItem.to === vm.$route.path
        : false
    }
  }
}
</script>
