import { Vue } from 'bootstrap-vue/src/vue'
import { NAME_NAV_ITEM_DROPDOWN } from 'bootstrap-vue/src/constants/components'
import {
  SLOT_NAME_BUTTON_CONTENT,
  SLOT_NAME_DEFAULT,
  SLOT_NAME_TEXT
} from 'bootstrap-vue/src/constants/slots'
import { PROP_TYPE_OBJECT_STRING } from 'bootstrap-vue/src/constants/props'
import { htmlOrText } from 'bootstrap-vue/src/utils/html'
import { keys, pick, sortKeys } from 'bootstrap-vue/src/utils/object'
import { makePropsConfigurable, makeProp } from 'bootstrap-vue/src/utils/props'
import { idMixin, props as idProps } from 'bootstrap-vue/src/mixins/id'
import { normalizeSlotMixin } from 'bootstrap-vue/src/mixins/normalize-slot'
import { props as BDropdownProps } from 'bootstrap-vue/src/components/dropdown/dropdown'
import { BLink } from 'bootstrap-vue/src/components/link/link'
import {
  dropdownMixin,
  props as dropdownProps
} from '~/components/BootstrapVue/Dropdown/dropdown'

// --- Props ---

export const props = makePropsConfigurable(
  sortKeys({
    to: makeProp(PROP_TYPE_OBJECT_STRING),
    dropdownLinkClasses: makeProp(PROP_TYPE_OBJECT_STRING),
    ...idProps,
    ...pick(BDropdownProps, [
      ...keys(dropdownProps),
      'html',
      'lazy',
      'menuClass',
      'noCaret',
      'role',
      'text',
      'toggleClass'
    ])
  }),
  NAME_NAV_ITEM_DROPDOWN
)

// --- Main component ---

// @vue/component
export const BNavItemDropdown = /* #__PURE__ */ Vue.extend({
  name: NAME_NAV_ITEM_DROPDOWN,
  mixins: [idMixin, dropdownMixin, normalizeSlotMixin],
  props,
  computed: {
    toggleId () {
      return this.safeId('_BV_toggle_')
    },
    dropdownClasses () {
      return [this.directionClass, this.boundaryClass, { show: this.visible }]
    },
    menuClasses () {
      return [
        this.menuClass,
        {
          'dropdown-menu-right': this.right,
          show: this.visible
        }
      ]
    },
    toggleClasses () {
      return [
        this.toggleClass,
        { 'dropdown-toggle-no-caret': this.noCaret },
        this.dropdownLinkClasses
      ]
    }
  },
  render (h) {
    const { toggleId, visible, hide } = this

    const $toggle = h(
      BLink,
      {
        staticClass: 'nav-link dropdown-toggle',
        class: this.toggleClasses,
        props: {
          to: this.to,
          disabled: this.disabled
        },
        attrs: {
          id: toggleId,
          role: 'button',
          'aria-haspopup': 'true',
          'aria-expanded': visible ? 'true' : 'false'
        },
        on: {
          mousedown: this.onMousedown,
          click: event => this.hide(true) && this.$emit('click', event),
          keydown: this.toggle // Handle ENTER, SPACE and DOWN
        },
        ref: 'toggle'
      },
      [
        // TODO: The `text` slot is deprecated in favor of the `button-content` slot
        this.normalizeSlot([SLOT_NAME_BUTTON_CONTENT, SLOT_NAME_TEXT]) ||
        h('span', { domProps: htmlOrText(this.html, this.text) })
      ]
    )

    const $menu = h(
      'div',
      {
        staticClass: 'dropdown-menu-wrapper'
      },
      [
        h(
          'ul',
          {
            staticClass: 'dropdown-menu',
            class: this.menuClasses,
            attrs: {
              tabindex: '-1',
              'aria-labelledby': toggleId
            },
            on: {
              keydown: this.onKeydown // Handle UP, DOWN and ESC
            },
            ref: 'menu'
          },
          !this.lazy || visible
            ? this.normalizeSlot(SLOT_NAME_DEFAULT, { hide })
            : [h()]
        )
      ]
    )

    return h(
      'li',
      {
        staticClass: 'nav-item b-nav-dropdown dropdown',
        class: this.dropdownClasses,
        attrs: { id: this.safeId() }
      },
      [$toggle, $menu]
    )
  }
})
