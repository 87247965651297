<template>
  <b-navbar-toggle
    v-b-toggle.menu-sidebar
    target="menu-sidebar"
    class="border-0"
  >
    <span class="navbar-toggler-icon" />
  </b-navbar-toggle>
</template>

<script>
export default {
  name: 'JbBurger'
}
</script>
