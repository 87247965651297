<template>
  <b-sidebar
    id="menu-sidebar"
    ref="menuSidebar"
    v-b-scrollspy
    title="Menu"
    :text-variant="null"
    :backdrop="true"
    :backdrop-variant="null"
    :bg-variant="null"
    body-class="navbar-nav"
    :right="true"
  >
    <template #default>
      <ul id="nav-collapse" class="navbar-nav">
        <slot />
      </ul>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: 'JbNavCollapse'
}
</script>
