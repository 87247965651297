var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{attrs:{"type":"dark","toggleable":"lg"}},[_c('b-container',[_c('jb-burger'),_vm._v(" "),_c('jb-nav-collapse',{ref:"navCollapse"},[_vm._l((_vm.navItems),function(item){return _c('jb-nav-item',{key:item.to,attrs:{"nav-item":item}})}),_vm._v(" "),_c('jb-nav-item',{attrs:{"nav-item":{
          href: 'https://www.instagram.com/4in1cleancz/',
          target: '_blank',
          class: 'ml-auto'
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fa',{attrs:{"icon":['fab', 'instagram'],"fixed-width":""}})]},proxy:true}])}),_vm._v(" "),_c('client-only',[(_vm.$auth.loggedIn)?[_c('jb-nav-item',{attrs:{"nav-item":{
              to: '/tajne/objednavky',
              content: 'Objednávky'
            }},on:{"click":_vm.hide}}),_vm._v(" "),_c('jb-nav-item',{attrs:{"nav-item":{
              content: 'Odhlásit se'
            }},on:{"click":_vm.logout},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fa',{attrs:{"icon":"sign-out-alt","fixed-width":""}})]},proxy:true}],null,false,726770188)})]:_vm._e()],2)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }