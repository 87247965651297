import { Vue } from 'bootstrap-vue/src/vue'
import { NAME_DROPDOWN_ITEM } from 'bootstrap-vue/src/constants/components'
import { EVENT_NAME_CLICK } from 'bootstrap-vue/src/constants/events'
import {
  PROP_TYPE_ARRAY_OBJECT_STRING,
  PROP_TYPE_STRING
} from 'bootstrap-vue/src/constants/props'
import { requestAF } from 'bootstrap-vue/src/utils/dom'
import { omit, sortKeys } from 'bootstrap-vue/src/utils/object'
import {
  makeProp,
  makePropsConfigurable,
  pluckProps
} from 'bootstrap-vue/src/utils/props'
import { attrsMixin } from 'bootstrap-vue/src/mixins/attrs'
import { normalizeSlotMixin } from 'bootstrap-vue/src/mixins/normalize-slot'
import {
  // BLink,
  props as BLinkProps
} from 'bootstrap-vue/src/components/link/link'

// --- Props ---

const linkProps = omit(BLinkProps, ['event', 'routerTag'])

export const props = makePropsConfigurable(
  sortKeys({
    ...linkProps,
    linkClass: makeProp(PROP_TYPE_ARRAY_OBJECT_STRING),
    variant: makeProp(PROP_TYPE_STRING)
  }),
  NAME_DROPDOWN_ITEM
)

// --- Main component ---

// @vue/component
export const BDropdownItem = /* #__PURE__ */ Vue.extend({
  name: NAME_DROPDOWN_ITEM,
  mixins: [attrsMixin, normalizeSlotMixin],
  inject: {
    bvDropdown: { default: null }
  },
  inheritAttrs: false,
  props,
  computed: {
    computedAttrs () {
      return {
        ...this.bvAttrs,
        role: 'menuitem'
      }
    }
  },
  methods: {
    closeDropdown () {
      // Close on next animation frame to allow <b-link> time to process
      requestAF(() => {
        if (this.bvDropdown) {
          this.bvDropdown.hide(true)
        }
      })
    },
    onClick (event) {
      this.$emit(EVENT_NAME_CLICK, event)
      this.closeDropdown()
    }
  },
  render (h) {
    const { linkClass, variant, active, disabled, onClick, bvAttrs } = this

    return h(
      'li',
      {
        class: bvAttrs.class,
        style: bvAttrs.style,
        attrs: { role: 'presentation' }
      },
      [
        h(
          'nuxt-link',
          {
            staticClass: 'dropdown-item',
            class: [
              linkClass,
              { [`text-${variant}`]: variant && !(active || disabled) }
            ],
            props: pluckProps(linkProps, this.$props),
            attrs: this.computedAttrs,
            nativeOn: { click: onClick },
            ref: 'item'
          },
          this.normalizeSlot()
        )
      ]
    )
  }
})
