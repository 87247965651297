<template>
  <header
    :class="{
      'header--unpinned': scrolled,
      'header--pinned': !scrolled,
      'header--intro': lastPosition < 1,
    }"
    class="header"
  >
    <jb-nav />
  </header>
</template>

<script>
import JbNav from '~/components/Core/JbNav/JbNav'
export default {
  name: 'JbHeader',
  components: { JbNav },
  props: {
    hideOnScroll: {
      type: Boolean,
      default: false
    },
    limitPosition: {
      type: Number,
      default: 300
    }
  },
  data: () => ({
    scrolled: false,
    lastPosition: 0
  }),
  created () {
    if (process.client) {
      // eslint-disable-next-line nuxt/no-globals-in-created
      window.addEventListener('scroll', this.handleScroll)
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      if (
        this.hideOnScroll &&
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true // move up!
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false // move down
      }

      this.lastPosition = window.scrollY
    }
  }
}
</script>

<style lang="scss">
@import "~assets/scss/vars/initial";
@import "~assets/scss/partials/header";
</style>
